.blog-card-wrapper {
    text-decoration: none;
    color: inherit
}

.blog-card{
    background-color:rgb(148, 148, 148);
    padding: 10px;
    margin: 30px;
    border-radius: 5px;
}

.blog-card:hover{
    transform: rotateZ(3deg);
}

.blog-card p:last-of-type {
    text-decoration: underline;
}


@media only screen and (max-width: 560px) {
    .blog-card-wrapper * {
        font-size: 1.8rem !important;
    }
  }