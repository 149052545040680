.image-page-wrapper h1 {
    margin-top: 60px;
    margin-bottom: 30px;
}

.images-container {
    padding: 50px;
    display: flex;
    flex-wrap: wrap;
}

.image-wrapper {
    height: 70vh;
    flex-grow: 1;
    position: relative;
    max-width: 80vw;
}

.images-container .image {
  max-height: 100%;
  min-width: 100%;
  object-fit: cover;
  vertical-align: bottom;
}

.images-container .delete-icon {
    position: absolute;
    right: 0;
    display: none;
    cursor: pointer;
    z-index: 5;
}

.image-wrapper:hover .delete-icon{
    display:inline;
}


@media only screen and (max-width: 870px) {
    .image-wrapper {
      height: 50vh !important;
  }
  .images-container {
    padding-left: 10px !important;
    padding-right: 10px !important;
    justify-content: center !important;
}
  }


  @media only screen and (max-width: 560px) {
    .image-wrapper {
      height: 30vh !important;
  }
  .images-container {
    padding-left: 0 !important;
    padding-right: 0 !important;
    justify-content: center !important;
}
  }

  @media only screen and (max-width: 560px) {
    .image-page-wrapper h1 {
      font-size: 4rem !important;
    }
  }


 