.video-page-wrapper h1 {
    margin-top: 60px;
    margin-bottom: 30px;
}

.videos-container {
    padding: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    
}


.video-wrapper {
    position: relative;
    width: 100%;
}

.videos-container video {
    width: 100%;
    height: 100%;
}


.video-wrapper .delete-icon {
    position: absolute;
    right: 0;
    display: none;
    cursor: pointer;
    z-index: 10;
}

.video-wrapper:hover .delete-icon{
    display:inline;
    position: absolute;

}


@media only screen and (max-width: 560px) {
    .videos-container {
      padding: 10px !important;
  }
  }

  @media only screen and (max-width: 560px) {
    .video-page-wrapper h1 {
      font-size: 4rem !important;
    }
  }
