.add-blog-wrapper > div > h2 {
  margin-top: 60px;
  margin-bottom: 30px;
}

.add-blog-wrapper form label,
.add-blog-wrapper form input,
.add-blog-wrapper form textarea {
  display: block;
  width: 80%;
  margin: 10px auto;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;
}


.text-input {
    border: 1px solid #F1F1F1
}

#submit-blog-input{
    width: fit-content;
}


.submit-blog-btn {
  background-color: rgb(97, 97, 97);
  color: rgb(198, 197, 199);
  padding: 10px 40px;
  margin: 10px 0 50px 0;
  border: 1px solid rgb(198, 197, 199);
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;

}

.submit-blog-btn:hover {
  color: rgb(241, 240, 240);
  border: 1px solid rgb(241, 240, 240);
}

.submit-blog-btn-disabled {
  background-color: rgb(97, 97, 97);
  color: rgb(198, 197, 199);
  padding: 10px 40px;
  margin: 10px 0 50px 0;
  border: 1px solid rgb(198, 197, 199);
  border-radius: 5px;
  font-size: 20px;
}

.image-error {
  margin-bottom: 30px;
}

#file-upload-input-blog {
  cursor: pointer;
}

@media only screen and (max-width: 560px) {
  .add-blog-wrapper form label,
  .add-blog-wrapper form input,
  .add-blog-wrapper form textarea {
    display: block;
    width: 90%;
    margin: 10px auto;
}
}


@media only screen and (max-width: 560px) {
  .add-blog-wrapper * {
      font-size: 2rem !important;
  }

  .add-blog-wrapper > div > h2 {
    font-size: 3rem !important;
  }
}