@import url(https://fonts.googleapis.com/css2?family=Ma+Shan+Zheng&family=Noto+Sans+TC:wght@100;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Long+Cang&family=Ma+Shan+Zheng&family=Noto+Sans+TC:wght@100;400;700&display=swap);
/* FONT IMOPORTS */



* {
  margin: 0;
  
}


html{
  margin: 0% 10%;
  background-color: rgb(0, 0, 0);
}

body {
  font-family: 'Noto Sans TC', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(97, 97, 97);
  /*border-radius: 8px;*/
  position: relative;
  /*border: 1px solid #f1f1f1;*/
  color: rgb(198, 197, 199);
  max-width: 1500px;
}


#logged-in{
  margin-top: 60px;
}

#logged-in button{
  margin-bottom: 50vh;
}


@media only screen and (max-width: 560px) {
  html {
    font-size: 0.5em;
    margin: 0;
  }
}
.App {
  text-align: center;
}

.btn {
  background-color: rgb(97, 97, 97);
  color: rgb(198, 197, 199);
  padding: 10px 40px;
  margin: 35px 0 5px 0;
  border: 1px solid rgb(198, 197, 199);
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;
}

.btn:hover {
  color: rgb(241, 240, 240);
  border: 1px solid rgb(241, 240, 240);
}

.modal h5 {
  margin-top: 30px;
  color: rgb(37,37,37)
}

.home{
    padding: 30px 100px 100px 100px;
    text-align: justify;
    line-height: 1.5em;
}

.home h1, .home h3 {
    text-align: center;
    padding: 10px;
}

.home h1{
    margin-top: 50px;
}

.table {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: -webkit-max-content;
    grid-auto-rows: max-content;
}

.table div {
    text-align: center;
    border: 1px solid rgb(145, 145, 145);
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 5px;
}


@media only screen and (max-width: 560px) {
    .home {
        padding: 30px 20px 30px 20px !important;
        line-height: 2.5em
    }
  
  }

  @media only screen and (max-width: 560px) {
    .home * {
        font-size: 2rem;
    }

    .home h1 {
        font-size: 4rem;
    }

    .home h3 {
        font-size: 3rem;
    }
  }
.blog-card-wrapper {
    text-decoration: none;
    color: inherit
}

.blog-card{
    background-color:rgb(148, 148, 148);
    padding: 10px;
    margin: 30px;
    border-radius: 5px;
}

.blog-card:hover{
    -webkit-transform: rotateZ(3deg);
            transform: rotateZ(3deg);
}

.blog-card p:last-of-type {
    text-decoration: underline;
}


@media only screen and (max-width: 560px) {
    .blog-card-wrapper * {
        font-size: 1.8rem !important;
    }
  }
.blog-page-wrapper > h1 {
    margin-top: 60px;
    margin-bottom: 30px;
}

#add-blog-btn{
    margin-bottom: 40px;
}

.blog-prevs-ctn{
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0px 30px 30px 30px;
}

@media only screen and (max-width: 560px) {
    .blog-prevs-ctn {
        grid-template-columns: 1fr !important;
    }
    .blog-page-wrapper > h1 {
       font-size: 4rem;
    }
  }
.add-img-wrapper {
    border: 1px solid #c2c1c1;
    display: flex;
    justify-content: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 5px;
    background-color: rgb(148, 148, 148);
}


#submit-img-btn {
    margin-top: 10px;
   
}

#submit-img-btn-disabled {
    margin-top: 10px;
    background-color: rgb(97, 97, 97);
    color: rgb(198, 197, 199);
    border: 1px solid rgb(198, 197, 199);
    border-radius: 5px;
    font-size: 20px;
    cursor:auto;
    cursor:initial;
}


#upload-img-file {
    display: block;
    margin: auto;
    margin-top: 20px;
    text-align:right;
    width:-webkit-min-content;
    width:min-content;
    padding-left: 80px;
    object-fit: contain;
    cursor: pointer;
}

.add-img-wrapper h3 {
    font-size: 20px;
}


@media only screen and (max-width: 560px) {
    .add-img-wrapper * {
        font-size: 1.8rem !important;
    }
  }
.image-page-wrapper h1 {
    margin-top: 60px;
    margin-bottom: 30px;
}

.images-container {
    padding: 50px;
    display: flex;
    flex-wrap: wrap;
}

.image-wrapper {
    height: 70vh;
    flex-grow: 1;
    position: relative;
    max-width: 80vw;
}

.images-container .image {
  max-height: 100%;
  min-width: 100%;
  object-fit: cover;
  vertical-align: bottom;
}

.images-container .delete-icon {
    position: absolute;
    right: 0;
    display: none;
    cursor: pointer;
    z-index: 5;
}

.image-wrapper:hover .delete-icon{
    display:inline;
}


@media only screen and (max-width: 870px) {
    .image-wrapper {
      height: 50vh !important;
  }
  .images-container {
    padding-left: 10px !important;
    padding-right: 10px !important;
    justify-content: center !important;
}
  }


  @media only screen and (max-width: 560px) {
    .image-wrapper {
      height: 30vh !important;
  }
  .images-container {
    padding-left: 0 !important;
    padding-right: 0 !important;
    justify-content: center !important;
}
  }

  @media only screen and (max-width: 560px) {
    .image-page-wrapper h1 {
      font-size: 4rem !important;
    }
  }


 
.add-video-wrapper {
    border: 1px solid #c2c1c1;
    display: flex;
    justify-content: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 5px;
    background-color: rgb(148, 148, 148);
}


#submit-vid-btn {
    margin-top: 20px;
   
}

#upload-vid-file {
    display: block;
    margin: auto;
    margin-top: 20px;
    text-align:right;
    width:-webkit-min-content;
    width:min-content;
    padding-left: 80px;
    object-fit: contain;
}

#submit-vid-btn-disabled {
    margin-top: 10px;
    background-color: rgb(97, 97, 97);
    color: rgb(198, 197, 199);
    border: 1px solid rgb(198, 197, 199);
    border-radius: 5px;
    font-size: 20px;
    cursor:auto;
    cursor:initial;
}


@media only screen and (max-width: 560px) {
    .add-video-wrapper * {
        font-size: 1.8rem !important;
    }
  }

.video-page-wrapper h1 {
    margin-top: 60px;
    margin-bottom: 30px;
}

.videos-container {
    padding: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    
}


.video-wrapper {
    position: relative;
    width: 100%;
}

.videos-container video {
    width: 100%;
    height: 100%;
}


.video-wrapper .delete-icon {
    position: absolute;
    right: 0;
    display: none;
    cursor: pointer;
    z-index: 10;
}

.video-wrapper:hover .delete-icon{
    display:inline;
    position: absolute;

}


@media only screen and (max-width: 560px) {
    .videos-container {
      padding: 10px !important;
  }
  }

  @media only screen and (max-width: 560px) {
    .video-page-wrapper h1 {
      font-size: 4rem !important;
    }
  }

.contacts {
    margin-top: 60px;
    margin-bottom: 30px;
}

.contact-page-container p {
    padding: 20px;
}

.contact-page-container p:last-of-type {
    margin-bottom: 40px
}

@media only screen and (max-width: 560px) {
    .contact-page-container * {
        font-size: 2.5rem
    }
    .contact-page-container h1 {
        font-size: 4rem !important;
    }
  }
.blog-details-page {
  padding: 30px 100px 100px 100px;
}

.btn-wrapper{
    display: flex;
    margin-left: 0px;
    margin-right: 0px;
    justify-content:space-between
    
}

.return-btn {
  background-color: rgb(97, 97, 97);
  color: rgb(198, 197, 199);
  padding: 10px 40px;
  margin: 35px 0 5px 0;
  border: 1px solid rgb(198, 197, 199);
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;

}

.return-btn:hover {
  color: rgb(241, 240, 240);
  border: 1px solid rgb(241, 240, 240);
}

.delete-btn {
  background-color: rgb(97, 97, 97);
  color: rgb(198, 197, 199);
  padding: 10px 40px;
  margin: 35px 0 5px 0;
  border: 1px solid rgb(198, 197, 199);
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;

}

.delete-btn:hover {
  color: rgb(241, 240, 240);
  border: 1px solid rgb(241, 240, 240);
}

.blog-details-wrapper {
  line-height: 1.5em;
}

.blog-details-title {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 50px;
}

.blog-details-date {
  text-align: center;
  margin-bottom: 20px;
}

.blog-details-body {
  text-align: justify
}

.blog-details-img {
  margin: auto;
  padding-top: 10px;
  padding-bottom: 50px;
  max-width: 80%;
}

@media only screen and (max-width: 650px) {
  .blog-details-page {
    padding: 30px 20px 20px 30px !important;
  }
  .return-btn, .delete-btn {
    font-size: 1em !important;
    padding: 5px 10px !important;
  }
}


@media only screen and (max-width: 560px) {
  .blog-details-page * {
      font-size: 2rem !important;
  }
}

.modal-backdrop{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    left: 0;
    display:flex;
    justify-content: center;
    align-items: center;


}

.modal {
    background-color: white;
    border-radius: 5px;
    position: relative;
    height: 30vh;
    width: 30vw;
    min-width: 250px;
}

.modal .close-btn{
    border: none;
    background-color: #fff;
    font-size: 25px;
    position: absolute;
    top: 3px;
    right: 3px;
    cursor: pointer;
}

.modal .close-btn:hover{
    color:rgb(196, 194, 194)
}

.modal-submit-btn {
    background-color: white;
    color: rgb(37,37,37);
    padding: 10px 40px;
    margin: 40px 0 15px 0;
    border: 1px solid rgb(37,37,37);
    border-radius: 5px;
    cursor: pointer;
    font-size: 20px;
}

.modal-submit-btn:hover {
    color:rgb(148, 148, 148);
    border: 1px solid rgb(148, 148, 148);
}

.modal h3 {
    color: rgb(37,37,37);
    margin-top: 40px
}
.add-blog-wrapper > div > h2 {
  margin-top: 60px;
  margin-bottom: 30px;
}

.add-blog-wrapper form label,
.add-blog-wrapper form input,
.add-blog-wrapper form textarea {
  display: block;
  width: 80%;
  margin: 10px auto;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;
}


.text-input {
    border: 1px solid #F1F1F1
}

#submit-blog-input{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}


.submit-blog-btn {
  background-color: rgb(97, 97, 97);
  color: rgb(198, 197, 199);
  padding: 10px 40px;
  margin: 10px 0 50px 0;
  border: 1px solid rgb(198, 197, 199);
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;

}

.submit-blog-btn:hover {
  color: rgb(241, 240, 240);
  border: 1px solid rgb(241, 240, 240);
}

.submit-blog-btn-disabled {
  background-color: rgb(97, 97, 97);
  color: rgb(198, 197, 199);
  padding: 10px 40px;
  margin: 10px 0 50px 0;
  border: 1px solid rgb(198, 197, 199);
  border-radius: 5px;
  font-size: 20px;
}

.image-error {
  margin-bottom: 30px;
}

#file-upload-input-blog {
  cursor: pointer;
}

@media only screen and (max-width: 560px) {
  .add-blog-wrapper form label,
  .add-blog-wrapper form input,
  .add-blog-wrapper form textarea {
    display: block;
    width: 90%;
    margin: 10px auto;
}
}


@media only screen and (max-width: 560px) {
  .add-blog-wrapper * {
      font-size: 2rem !important;
  }

  .add-blog-wrapper > div > h2 {
    font-size: 3rem !important;
  }
}
.cover img {
  width: 100%;
  opacity: 0.7;
}

.navbar {
  /*  position:sticky;
    top: 0;
    z-index: 2; */
  /*background-color: rgb(128, 127, 127);*/
  background: url(/static/media/cover3.920246a8.png);
  background-size: cover;
  height: 80vh;
  position: relative;
}

.avatar {
  height: 150px;
  width: 150px;
  border-radius: 100%;
  border: 4px solid rgb(198, 197, 199);
  position: fixed;
  top: 0;
  left: 10%;
  z-index: 2;
}

.logo {
  height: 5vh;
  position: absolute;
  left: 180px;
  top: 30px;
  -webkit-transform: rotateZ(-30deg);
          transform: rotateZ(-30deg);
}
.signature {
  font-family: "Long Cang", cursive;
}

.header {
  display: flex;
  justify-content: flex-end;
  /*border-bottom: 1px solid #f1f1f1;*/
}

.header a {
  text-decoration: none;
  margin: 40px 30px 10px 30px;
  padding-bottom: 5px;
  padding-left: 2px;
  padding-right: 2px;

  /*border-left: 1px solid #d4d4d4;*/
  font-size: 22px;
  color: rgba(198, 197, 199, 1);
  z-index: 2;
}

.header a:hover {
  border-bottom: 3px solid rgb(241, 240, 240);
  color: rgb(241, 240, 240);
}

.user {
  position: absolute;
  left: 0;
  bottom: 0;
  font-size: 0.7em;
}

.active {
  border-bottom: 3px solid rgb(241, 240, 240);
  color: rgb(241, 240, 240) !important;
}



@media only screen and (max-width: 1000px) {
  .avatar {
    display: none;
  }
}


@media only screen and (max-width: 760px) {
  .header{
    justify-content: space-evenly;
  }
  .tab {
    -webkit-writing-mode: vertical-rl;
        -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl;
  }
}


@media only screen and (max-width: 560px) {
  html {
    font-size: 0.5em;
  }
  .tab {
    font-size: 3em !important;
    margin-left: 5px !important;
    margin-right: 5px !important;

  }

}
.footer {
    border-top: 1px solid gray;
    height: 40vh;
    background-color: rgb(32, 32, 32);
}

.footer p {
    margin-top: 30px;
    margin-bottom: 30px;

}


@media only screen and (max-width: 560px) {
    .footer p {
        font-size: 2.5rem !important;
    }
  }
.redirect-container {
    height: 80vh;
}

.redirect-container h1 {
    margin-top: 60px;
    margin-bottom: 30px;
}
.Signup_signup-form__2rWEZ {
    max-width: 360px;
    margin: 60px auto;
    padding: 20px;
  }
  .Signup_signup-form__2rWEZ label {
    display: block;
    margin: 30px auto;
  }
  .Signup_signup-form__2rWEZ span {
    display: block;
    margin-bottom: 6px;
  }
  .Signup_signup-form__2rWEZ input {
    padding: 8px 6px;
    font-size: 1em;
    color: #777;
    width: 100%;
  }
.Login_login-form__3IH5q {
    max-width: 360px;
    margin: 40px auto;
    padding: 20px;
  }
  .Login_login-form__3IH5q label {
    display: block;
    margin: 30px auto;
    position: relative;
  }
  .Login_login-form__3IH5q span {
    display: block;
    margin-bottom: 6px;
  }
  .Login_login-form__3IH5q input {
    padding: 8px 6px;
    font-size: 1em;
    color: #777;
    width: 100%;
    margin: auto;
    box-sizing: border-box;
  }

