.footer {
    border-top: 1px solid gray;
    height: 40vh;
    background-color: rgb(32, 32, 32);
}

.footer p {
    margin-top: 30px;
    margin-bottom: 30px;

}


@media only screen and (max-width: 560px) {
    .footer p {
        font-size: 2.5rem !important;
    }
  }