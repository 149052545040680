
.modal-backdrop{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    left: 0;
    display:flex;
    justify-content: center;
    align-items: center;


}

.modal {
    background-color: white;
    border-radius: 5px;
    position: relative;
    height: 30vh;
    width: 30vw;
    min-width: 250px;
}

.modal .close-btn{
    border: none;
    background-color: #fff;
    font-size: 25px;
    position: absolute;
    top: 3px;
    right: 3px;
    cursor: pointer;
}

.modal .close-btn:hover{
    color:rgb(196, 194, 194)
}

.modal-submit-btn {
    background-color: white;
    color: rgb(37,37,37);
    padding: 10px 40px;
    margin: 40px 0 15px 0;
    border: 1px solid rgb(37,37,37);
    border-radius: 5px;
    cursor: pointer;
    font-size: 20px;
}

.modal-submit-btn:hover {
    color:rgb(148, 148, 148);
    border: 1px solid rgb(148, 148, 148);
}

.modal h3 {
    color: rgb(37,37,37);
    margin-top: 40px
}