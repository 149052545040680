.blog-details-page {
  padding: 30px 100px 100px 100px;
}

.btn-wrapper{
    display: flex;
    margin-left: 0px;
    margin-right: 0px;
    justify-content:space-between
    
}

.return-btn {
  background-color: rgb(97, 97, 97);
  color: rgb(198, 197, 199);
  padding: 10px 40px;
  margin: 35px 0 5px 0;
  border: 1px solid rgb(198, 197, 199);
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;

}

.return-btn:hover {
  color: rgb(241, 240, 240);
  border: 1px solid rgb(241, 240, 240);
}

.delete-btn {
  background-color: rgb(97, 97, 97);
  color: rgb(198, 197, 199);
  padding: 10px 40px;
  margin: 35px 0 5px 0;
  border: 1px solid rgb(198, 197, 199);
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;

}

.delete-btn:hover {
  color: rgb(241, 240, 240);
  border: 1px solid rgb(241, 240, 240);
}

.blog-details-wrapper {
  line-height: 1.5em;
}

.blog-details-title {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 50px;
}

.blog-details-date {
  text-align: center;
  margin-bottom: 20px;
}

.blog-details-body {
  text-align: justify
}

.blog-details-img {
  margin: auto;
  padding-top: 10px;
  padding-bottom: 50px;
  max-width: 80%;
}

@media only screen and (max-width: 650px) {
  .blog-details-page {
    padding: 30px 20px 20px 30px !important;
  }
  .return-btn, .delete-btn {
    font-size: 1em !important;
    padding: 5px 10px !important;
  }
}


@media only screen and (max-width: 560px) {
  .blog-details-page * {
      font-size: 2rem !important;
  }
}