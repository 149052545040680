/* FONT IMOPORTS */
@import url('https://fonts.googleapis.com/css2?family=Ma+Shan+Zheng&family=Noto+Sans+TC:wght@100;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Long+Cang&family=Ma+Shan+Zheng&family=Noto+Sans+TC:wght@100;400;700&display=swap');



* {
  margin: 0;
  
}


html{
  margin: 0% 10%;
  background-color: rgb(0, 0, 0);
}

body {
  font-family: 'Noto Sans TC', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(97, 97, 97);
  /*border-radius: 8px;*/
  position: relative;
  /*border: 1px solid #f1f1f1;*/
  color: rgb(198, 197, 199);
  max-width: 1500px;
}


#logged-in{
  margin-top: 60px;
}

#logged-in button{
  margin-bottom: 50vh;
}


@media only screen and (max-width: 560px) {
  html {
    font-size: 0.5em;
    margin: 0;
  }
}