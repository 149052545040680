.cover img {
  width: 100%;
  opacity: 0.7;
}

.navbar {
  /*  position:sticky;
    top: 0;
    z-index: 2; */
  /*background-color: rgb(128, 127, 127);*/
  background: url("../../assets/cover3.png");
  background-size: cover;
  height: 80vh;
  position: relative;
}

.avatar {
  height: 150px;
  width: 150px;
  border-radius: 100%;
  border: 4px solid rgb(198, 197, 199);
  position: fixed;
  top: 0;
  left: 10%;
  z-index: 2;
}

.logo {
  height: 5vh;
  position: absolute;
  left: 180px;
  top: 30px;
  transform: rotateZ(-30deg);
}
.signature {
  font-family: "Long Cang", cursive;
}

.header {
  display: flex;
  justify-content: flex-end;
  /*border-bottom: 1px solid #f1f1f1;*/
}

.header a {
  text-decoration: none;
  margin: 40px 30px 10px 30px;
  padding-bottom: 5px;
  padding-left: 2px;
  padding-right: 2px;

  /*border-left: 1px solid #d4d4d4;*/
  font-size: 22px;
  color: rgba(198, 197, 199, 1);
  z-index: 2;
}

.header a:hover {
  border-bottom: 3px solid rgb(241, 240, 240);
  color: rgb(241, 240, 240);
}

.user {
  position: absolute;
  left: 0;
  bottom: 0;
  font-size: 0.7em;
}

.active {
  border-bottom: 3px solid rgb(241, 240, 240);
  color: rgb(241, 240, 240) !important;
}



@media only screen and (max-width: 1000px) {
  .avatar {
    display: none;
  }
}


@media only screen and (max-width: 760px) {
  .header{
    justify-content: space-evenly;
  }
  .tab {
    writing-mode: vertical-rl;
  }
}


@media only screen and (max-width: 560px) {
  html {
    font-size: 0.5em;
  }
  .tab {
    font-size: 3em !important;
    margin-left: 5px !important;
    margin-right: 5px !important;

  }

}