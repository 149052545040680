.App {
  text-align: center;
}

.btn {
  background-color: rgb(97, 97, 97);
  color: rgb(198, 197, 199);
  padding: 10px 40px;
  margin: 35px 0 5px 0;
  border: 1px solid rgb(198, 197, 199);
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;
}

.btn:hover {
  color: rgb(241, 240, 240);
  border: 1px solid rgb(241, 240, 240);
}

.modal h5 {
  margin-top: 30px;
  color: rgb(37,37,37)
}
