.login-form {
    max-width: 360px;
    margin: 40px auto;
    padding: 20px;
  }
  .login-form label {
    display: block;
    margin: 30px auto;
    position: relative;
  }
  .login-form span {
    display: block;
    margin-bottom: 6px;
  }
  .login-form input {
    padding: 8px 6px;
    font-size: 1em;
    color: #777;
    width: 100%;
    margin: auto;
    box-sizing: border-box;
  }
